import React from "react";
import Helmet from "react-helmet";
import qs from "querystringify";

import "./App.css";
import { HostedForm, UnSubscribed, getStyles } from "@sidemail/hostedforms";

const idRegex = /^[0-9a-fA-F]{24}$/;

function getTimezone() {
	return (
		Intl &&
		Intl.DateTimeFormat &&
		Intl.DateTimeFormat().resolvedOptions().timeZone
	);
}

const DelayedLoader = ({ delay = 60, ...rest }) => {
	const [isVisible, setIsVisible] = React.useState(false);

	React.useEffect(() => {
		let timer = setTimeout(() => setIsVisible(true), delay);

		return () => {
			clearTimeout(timer);
		};
	}, []);

	return (
		isVisible && (
			<div
				style={{
					height: "100vh",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					fontSize: 30,
					letterSpacing: "1px",
					color: "#3f3f3f",
				}}>
				Loading...
			</div>
		)
	);
};

class SubscribePage extends React.Component {
	constructor(props) {
		super(props);

		const defaultState = {
			content: null,
			design: null,
			data: {
				emailAddress: "",
			},
		};

		const queryData = qs.parse(props.navigation.query);

		if (queryData.emailAddress && queryData.emailAddress.length > 0) {
			defaultState.data.emailAddress = queryData.emailAddress.trim();
		}

		this.state = defaultState;
	}

	async componentDidMount() {
		const response = await fetch(
			`/backend/contacts/hostedforms/content?formId=${this.props.navigation.pathSegments[0]}`
		);
		const data = await response.json();

		// Set default value from query string
		data.value.content.fields.forEach((field) => {
			if (field.keyName === "emailAddress") {
				field.defaultValue = this.state.data.emailAddress;
			}
		});

		this.setState({ content: data.value.content, design: data.value.design });
	}

	handleSubmit = async (data) => {
		const reqData = {
			value: {
				emailAddress: data.emailAddress,
				timezone: getTimezone(),
				customProps: Object.entries(data)
					.filter(([keyName, value]) => {
						if (keyName === "emailAddress") {
							return false;
						}

						return true;
					})
					.map(([keyName, value]) => {
						return {
							keyName,
							value,
						};
					}),
			},
			formId: this.props.navigation.pathSegments[0],
		};

		let response;

		try {
			response = await fetch("/backend/contacts/hostedforms/contact", {
				method: "POST",
				body: JSON.stringify(reqData),
				headers: { "Content-Type": "application/json" },
			});
		} catch (err) {
			if (err) {
				throw new Error(
					"It seems you're offline, please, try again when you're back online."
				);
			}
		}

		const responseData = await response.json();

		if (response.status !== 200) {
			throw new Error(responseData.developerMessage);
		}
	};

	render() {
		if (!this.state.content) {
			return <DelayedLoader />;
		}

		return (
			<>
				<Helmet>
					<title>{this.state.content.title}</title>
					<style type="text/css">
						{getStyles({ colors: this.state.design.colors })}
					</style>
					<meta
						name="theme-color"
						content={this.state.design.colors.highlight}
					/>
				</Helmet>

				<HostedForm
					form={{ content: this.state.content }}
					project={{ emailTemplateDesign: this.state.design }}
					onSubmit={this.handleSubmit}
				/>
			</>
		);
	}
}

class UnSubscribePage extends React.Component {
	state = {
		design: null,
		fetchState: null,
	};

	async componentDidMount() {
		try {
			if (this.props.navigation.query.includes("?p=")) {
				const base64Data = this.props.navigation.query.split("?p=")[1];
				const data = JSON.parse(atob(decodeURIComponent(base64Data)));

				const response = await fetch(
					`/backend/contacts/hostedforms/unsubscribe`,
					{
						method: "POST",
						body: JSON.stringify({
							...data,
							contactId: data.cid,
						}),
						headers: {
							"Content-Type": "application/json",
						},
					}
				);
				const responseData = await response.json();

				this.setState({ design: responseData.value.design });
			}
		} catch (err) {
			this.setState({ fetchState: "error" });
		}
	}

	render() {
		if (this.state.fetchState === "error") {
			return "This unsubscribe link is broken. Please, reply to the email where you clicked it and let us know to unsubscribe you. Thank you! ";
		}

		if (!this.state.design) {
			return null;
		}

		return (
			<>
				<Helmet>
					<title>Unsubscribe</title>
					<style type="text/css">
						{getStyles({ colors: this.state.design.colors })}
					</style>
					<meta
						name="theme-color"
						content={this.state.design.colors.highlight}
					/>
				</Helmet>

				<UnSubscribed project={{ emailTemplateDesign: this.state.design }} />
			</>
		);
	}
}

class App extends React.Component {
	constructor(props) {
		super(props);

		const navigation = {
			path: window.location.pathname,
			query: window.location.search,
		};

		navigation.pathSegments = navigation.path.substring(1).split("/");

		this.state = {
			navigation,
		};
	}

	render() {
		const { navigation } = this.state;

		if (navigation.path === "/unsubscribe") {
			return <UnSubscribePage navigation={navigation} />;
		}

		if (
			navigation.pathSegments.length === 1 &&
			idRegex.test(navigation.pathSegments[0])
		) {
			return <SubscribePage navigation={navigation} />;
		}

		window.location.replace("https://sidemail.io");
		return null;
	}
}

export default App;

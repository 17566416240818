import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/browser";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

Sentry.init({
	dsn: "https://539f94c076f24ac5a83ee9c87421b4aa@sentry.io/1829755",
	environment: process.env.NODE_ENV,
	beforeSend: (event) => {
		if (event.environment !== "production") {
			return null;
		}

		return event;
	},
});

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
